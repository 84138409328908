import { useEffect, useMemo, useRef, useState } from "react";
import {
	Accent,
	Accordian,
	Banner,
	BannerCard,
	Button,
	CardList,
	Carosel,
	CaroselItem,
	Col,
	FaIcon,
	H1,
	H2,
	Hero,
	ImagePosition,
	InfoItem,
	InfoItemBullet,
	InfoLayout,
	LargeCard,
	OutlinkCard,
	P,
	PageLayout,
	PricingGrid,
	ReviewCard,
	Ribon,
	Row,
	Schedule,
	Section,
	SmallCard,
	Spacer,
	Tag,
	VideoCard,
	VideoItem,
	VideoList,
	ViewTransition
} from "@sage/shared";
import "./HomePage.scss";

const pageToColor = {
	"CIMs": "red",
	"Teasers": "teal",
	"Charts & Graphs": "purple",
	"Information Requests": "blue",
	"Buyer Questions": "green"
};

export function HomePage() {
	const [activeCaroselPage, setActiveCaroselPage] = useState("CIMs");
	const [videoBg, setVideoBg] = useState(pageToColor["CIMs"]);
	const intervalRef = useRef(null);

	function updateActiveCaroselPage(page: string) {
		clearInterval(intervalRef.current);
		setActiveCaroselPage(page);
		setVideoBg(pageToColor[page]);
	}

	function loopCarosel() {
		let idx = 0;
		let ids = ["CIMs", "Teasers", "Charts & Graphs", "Information Requests", "Buyer Questions"];
		return setInterval(() => {
			if (idx < ids.length - 1) {
				idx++;
			} else {
				idx = 0;
			}
			setActiveCaroselPage(ids[idx]);
			setVideoBg(pageToColor[ids[idx]]);
		}, 10000);
	}

	useEffect(() => {
		document.title = "Sage AI";
		intervalRef.current = loopCarosel();
		return () => {
			try {
				clearInterval(intervalRef.current);
			} catch (e) {}
		};
	}, []);

	return (
		<section className="__sage-home-page-container">
			<PageLayout size="wide">
				<Hero image={"https://cdn.sageai.dev/website/assets/new-hero.avif"}>
					<Tag>Specialized AI for Investment Banks</Tag>
					<span>Quality without complexity, Sage AI works for you.</span>
					<H2 light>
						In a business where <strong>every detail</strong> matters,
						<br />
						manual work shouldn't slow you down.
					</H2>
					<Row
						gap={"1rem"}
						horizontalAlign="left"
					>
						<Button
							variant={"dark"}
							href="/examples"
						>
							<Row
								verticalAlign="center"
								horizontalAlign="center"
							>
								See Examples
								<FaIcon
									icon={"video"}
									color={"#f7a501"}
									padding="0"
									minWidth="0"
									paddingInline="0.5rem"
									hideBg
								/>
							</Row>
						</Button>
						<Button
							variant={"blue"}
							href="#get-started"
						>
							<Row
								verticalAlign="center"
								horizontalAlign="center"
							>
								Try for free
								<FaIcon
									icon={"star"}
									color={"#FFFFFF"}
									padding="0"
									minWidth="0"
									paddingInline="0.5rem"
									hideBg
								/>
							</Row>
						</Button>
					</Row>
				</Hero>
			</PageLayout>
			<PageLayout size="wide">
				<Col
					horizontalAlign="center"
					width="100%"
					gap="2rem"
				>
					<Col
						gap="2.5rem"
						horizontalAlign="center"
						width={"100%"}
					>
						<Ribon>
							<FaIcon
								icon={"file-chart-column"}
								size="2x"
								color={"#a80300"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "CIMs"}
								onClick={() => updateActiveCaroselPage("CIMs")}
							>
								CIMs
							</FaIcon>
							<FaIcon
								icon={"memo"}
								size="2x"
								color={"#30abc6"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Teasers"}
								onClick={() => updateActiveCaroselPage("Teasers")}
							>
								Teasers
							</FaIcon>
							<FaIcon
								icon={"chart-pie-simple-circle-dollar"}
								size="2x"
								color={"#5700a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Charts & Graphs"}
								onClick={() => updateActiveCaroselPage("Charts & Graphs")}
							>
								Charts & Graphs
							</FaIcon>
							<FaIcon
								icon={"ballot-check"}
								size="2x"
								color={"#0051a8"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Information Requests"}
								onClick={() => updateActiveCaroselPage("Information Requests")}
							>
								Information Requests
							</FaIcon>
							<FaIcon
								icon={"handshake"}
								size="2x"
								color={"#00a857"}
								animation={"fa-bounce"}
								active={activeCaroselPage === "Buyer Questions"}
								onClick={() => updateActiveCaroselPage("Buyer Questions")}
							>
								Buyer Questions
							</FaIcon>
						</Ribon>
						<Carosel activePage={activeCaroselPage}>
							<CaroselItem
								id="CIMs"
								backgroundColor={"#e3adac"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/project-growth.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<Col gap={"0.5rem"}>
												<H1>From data to deal-ready CIMs in minutes</H1>
											</Col>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Generate complete Confidential Information Memorandums with smart templates and
													industry-specific insights at your direction
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Craft compelling investment theses and highlight key value drivers through AI-enhanced
													analysis of your data
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Teasers"
								backgroundColor={"#c0e5ee"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/project-teaser.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Capture attention with quality Teasers</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"business-time"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Create crisp, engaging teasers that protect confidentiality while showcasing your
													client's unique value proposition
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"print"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Develop multiple teaser variations to target different buyer segments, with precisely
													highlighted relevant synergies{" "}
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Charts & Graphs"
								backgroundColor={"#c8ace3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/project-charts.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Tell the growth story that sells</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-line-up"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>Transform your financial data into professional, deal-ready visualizations</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-network"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Create dynamic charts that showcase growth trends, margin expansion, and other key
													investment metrics
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Information Requests"
								backgroundColor={"#acc6e3"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/info-request.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Never chase documents again</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"list-check"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Streamline information gathering with smart questionnaires that identify and fill
													critical data gaps
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"sitemap"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Enhance your due diligence by quickly identifying potential issues and inconsistencies
													across documents
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Buyer Questions"
								backgroundColor={"#99dcbc"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/info-request.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Always ready with the right answer</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"memo"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Deliver instant, accurate responses to common buyer questions using your deal
													documentation{" "}
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"building"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Monitor buyer engagement patterns to identify serious prospects and their key areas of
													interest{" "}
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
							<CaroselItem
								id="Due Diligence"
								backgroundColor={"#ace3c8"}
							>
								<Row
									verticalAlign="top"
									height="100%"
									gap={"1rem"}
								>
									<div className="__sage-home-page-image">
										<ViewTransition full>
											<img src={"https://cdn.sageai.dev/website/assets/mockups/project-p-and-l.avif"} />
										</ViewTransition>
									</div>
									<div className="__sage-home-page-card-text">
										<Col gap={"2rem"}>
											<H1>Find Any Information in the Data Room In Seconds</H1>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"person-running-fast"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Enhance your due diligence by quickly identifying potential issues and inconsistencies
													across documents
												</P>
											</Row>
											<Row
												verticalAlign="center"
												wrap={false}
												gap={"1rem"}
											>
												<ViewTransition>
													<FaIcon
														icon={"chart-waterfall"}
														size="2x"
														color={"#1d1f27"}
														active={true}
														paddingTop={"1rem"}
														paddingBottom={"1rem"}
														width={"5rem"}
														hideBg
													/>
												</ViewTransition>
												<P>
													Build comprehensive due diligence reports with industry-specific analysis and risk
													assessments
												</P>
											</Row>
										</Col>
									</div>
								</Row>
							</CaroselItem>
						</Carosel>
					</Col>
				</Col>
			</PageLayout>
			<PageLayout>
				<Spacer></Spacer>
				<Row
					horizontalAlign="center"
					verticalAlign="center"
					width={"100%"}
				>
					<Col
						gap={"2rem"}
						maxWidth={"1000px"}
					>
						<H1>See How Investment Banks Are Using Sage AI.</H1>
						<H2 light>
							Watch a 5-minute demo of our AI platform automating the most time-consuming parts of deals, while maintaining
							your firm's quality standards.
						</H2>
						<VideoCard
							video={"https://sageai.dev/cdn/website/assets/sage-vsl.mp4"}
							thumbnail={"https://cdn.sageai.dev/website/assets/home-page-thumbnail-2.avif"}
							size={"medium"}
							borderColor={videoBg}
							controls
						/>
					</Col>
				</Row>
			</PageLayout>
			<Spacer></Spacer>
			<Section name="reviews">
				<Col gap="2rem">
					<H1
						size="xl"
						textAlign="center"
					>
						Sage AI is being used by dozens
						<br />
						of investment banks daily
					</H1>
					<Row
						gap="2rem"
						horizontalAlign="center"
					>
						<ReviewCard
							name={"Nationwide Tech Middle Market Boutique"}
							kicker={'"Essential to how we execute deals"'}
							image={"https://cdn.sageai.dev/website/assets/review-3.avif"}
						>
							"What started as a CIM creation tool has become essential to how we execute deals. The quality and seamless
							integration made committing long-term an easy choice. We're handling more deals without growing pains, and it's
							actually strengthened our brand consistency."
						</ReviewCard>
						<ReviewCard
							name={"Specialized Oil & Gas Boutique"}
							kicker={'"Integration with our workflows made adoption painless"'}
							image={"https://cdn.sageai.dev/website/assets/review-2.avif"}
						>
							"The real value isn't just in creating documents faster - it's that Sage AI maintains consistent quality across
							all our teams. We're running more deals without sacrificing our standards, and our juniors are spending more
							time on strategic work instead of document creation. The seamless integration with our existing workflow made
							adoption painless."
						</ReviewCard>
						<ReviewCard
							name={"Middle Market Boutique in the Southeast U.S."}
							kicker={'"It genuinely captured our voice and accelerated our process"'}
							image={"https://cdn.sageai.dev/website/assets/review-1.avif"}
						>
							"Within two weeks, Sage AI was creating CIMs that matched our quality standards. As a three-person team, we were
							skeptical of AI, but it genuinely captured our voice and accelerated our process. We're now taking on deals we
							would have previously passed on due to bandwidth constraints."
						</ReviewCard>
					</Row>
				</Col>
			</Section>
			<Spacer></Spacer>
			<Section name="who-is-this-for">
				<InfoLayout
					title={"Perfect for Specialized Boutiques, Middle Market Boutiques and Enterprise Investment Banks"}
					subtitle={
						<>
							Sage AI is the perfect choice for Investment Banks that do a handfull of deals per year, and for banks running
							dozens of live deals at a time.
							<br />
							<br />
							Sage is your competitive edge to run high quality, low friction deals
						</>
					}
				>
					<InfoItem
						title={"Specialized Boutiques"}
						icon="briefcase"
					>
						<InfoItemBullet>
							<strong>Lean Operations:</strong>
							<br />
							Automates core deal documents and diligence tasks, letting boutiques deliver large-bank quality without the
							headcount
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Knowledge Capture:</strong>
							<br />
							Preserves and leverages all client interactions and meeting insights, ensuring no deal knowledge slips through
							the cracks
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Client Response Time:</strong>
							<br />
							Handles buyer questions and diligence requests instantly, giving boutiques the rapid response power of large
							banks
						</InfoItemBullet>
					</InfoItem>
					<InfoItem
						title={"Middle Market Boutiques"}
						icon="people-group"
					>
						<InfoItemBullet>
							<strong>Team Coordination:</strong>
							<br />
							Streamlines deal document creation and diligence across multiple bankers, ensuring consistent quality and
							eliminating duplicate work
							<br />
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Knowledge Network:</strong>
							<br />
							Turns every call, meeting, and interaction into searchable deal insights, helping teams build on each other's
							client relationships
							<br />
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Deal Launch Speed:</strong>
							<br />
							Generates first-draft CIMs and teasers in hours instead of weeks, letting your growing team take on more
							mandates
							<br />
						</InfoItemBullet>
					</InfoItem>
					<InfoItem
						title={"Enterprise Investment Banks"}
						icon="buildings"
					>
						<InfoItemBullet>
							<strong>Cross-Team Intelligence:</strong>
							<br />
							Connects deal knowledge and client insights across your industry groups and offices, breaking down information
							silos
							<br />
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Platform Consistency:</strong>
							<br />
							Maintains uniform quality in deal documents and communications across your full organization while preserving
							sector expertise
							<br />
						</InfoItemBullet>
						<InfoItemBullet>
							<strong>Scale-Driven Efficiency:</strong>
							<br />
							Automates routine aspects of deal execution across teams, letting your bankers focus on the strategic work that
							drives value
							<br />
						</InfoItemBullet>
					</InfoItem>
				</InfoLayout>
			</Section>
			<PageLayout>
				<Section name="get-started">
					<LargeCard
						tagline="No Hastle Setup"
						title={
							<>
								From Signup to CIM Slides in <Accent>30 Minutes</Accent> or Less
							</>
						}
						subtitle={"No extensive setup or training required - just instant efficiency for your deal team. "}
						content={
							"Simply connect your data room and start getting answers in seconds. Whether it’s 20 files or 2000 files, Sage will find what you’re looking for in seconds.\n\nOur intuitive interface lets you hit the ground running. Pull key information, draft complex slides, and answer diligence questions."
						}
						image={"https://cdn.sageai.dev/website/assets/mockups/project-growth.avif"}
						imagePosition={ImagePosition.Right}
						imageBorder="none"
					/>
					<PricingGrid />
				</Section>
				<Section name="calendar">
					<Schedule />
				</Section>
			</PageLayout>
			<Spacer>
				<Banner
					src={"https://cdn.sageai.dev/website/assets/home-page-banner-4.avif"}
					size={"sm"}
					center={
						<BannerCard
							variant={"center"}
							title={"Trusted by Investment Banks across 11 countries"}
							color="dark"
							content={<img src="https://cdn.sageai.dev/website/assets/globe.avif" />}
						/>
					}
				/>
			</Spacer>
			<PageLayout>
				<LargeCard
					tagline="Security"
					title={
						<>
							Safe AI with <Accent>enterprise-grade</Accent> security at every level
						</>
					}
					content={
						"Enterprise-grade encryption protects your data, models, and proprietary knowledge.\n\nDedicated infrastructure ensures complete data isolation between clients.\n\nSage AI is the only Generative AI platform purpose-built for investment banking, Sage AI offers unparalleled security for your firm’s most valuable assets - your data and your people. "
					}
					image={"https://cdn.sageai.dev/website/assets/sec-2.avif"}
					imagePosition={ImagePosition.Left}
				/>
				<Accordian
					title={"Frequently Asked Questions"}
					hideImg
					sections={[
						{
							title: "How Does Sage AI Keep Everything 100% Private?",
							id: "privacy",
							content:
								"Sage AI implements enterprise-grade security at every level to ensure your data remains completely private. All data is encrypted both in transit and at rest using AWS infrastructure, with no third-party vendors involved.\n\nOur multi-tenant architecture ensures complete data isolation between clients through mandatory team ID authentication, meaning your firm's data can never be accessed by or mixed with other clients' information. We do not train our models on client data, and any support access requires explicit permission from your team.\n\nAs a tools company rather than a models company, our business model is built around protecting your privacy while helping you work more efficiently."
						},
						{
							title: "How Do You Ensure Data Is Not Used for Training?",
							id: "training",
							content:
								"At Sage AI, we are fundamentally a tools company, not a models company. This means we have no business incentive to train on your data - our goal is to provide you with powerful tools, not to build better AI models.\n\nWe maintain strict technical controls that prevent your data from being used for training: all information is siloed by company, encrypted end-to-end, and only accessible via secure authentication tokens. Any model training would require explicit written agreements, which we do not seek or encourage.\n\nUnlike general AI companies that may train on user data to improve their models, our business is built around helping investment banks work more efficiently while maintaining absolute confidentiality of their deal information. Your data is used solely to help you complete your work - nothing more."
						},
						{
							title: "How Do You Ensure Zero Chance of Data Breaches?",
							id: "data-breaches",
							content:
								"At Sage AI, we ensure zero chance of data breaches through multiple layers of enterprise-grade security. We use AWS's secure infrastructure with end-to-end encryption, strict data isolation between clients through mandatory team ID authentication, and no third-party vendor access.\n\nAll data is encrypted both in transit and at rest using industry-standard protocols. Our engineering team, with backgrounds from pioneers of modern internet infrastructure, has built security into every aspect of our platform.\n\nWe employ active security monitoring, and regularly review our security measures. For complete transparency, we're happy to work directly with your compliance team to explain our comprehensive security protocols in detail."
						},
						{
							title: "How Do You Ensure Safe and Accurate Responses?",
							id: "accuracy",
							content:
								"At Sage AI, we ensure safe and accurate responses by only using information from your connected data sources - never external websites or generic AI knowledge. When you ask a question, Sage searches solely through your data room, internal documents, and verified company websites that you've explicitly connected.\n\nEvery response is backed by direct citations to source documents, which you can instantly verify with a single click. If information isn't available in your connected sources, Sage will tell you directly rather than making assumptions. We also maintain strict version control of all documents, so you can track exactly where each piece of information originated. This source-based approach ensures that every response is accurate, verifiable, and based purely on your firm's trusted data."
						},
						{
							title: "What Does it Cost?",
							id: "cost",
							content:
								"Our pricing starts at just $240 per user per month, making Sage AI accessible for investment banks of all sizes. Each plan is customized to your firm's specific needs, volume of deals, and number of users. To get a detailed pricing breakdown and learn about volume discounts for your team, schedule a quick 10-minute call with us today. We'll walk you through how we can tailor our platform to maximize value for your specific use case."
						},
						{
							title: "How Long Can I Try It For?",
							id: "trial",
							content:
								"Our trial periods are customized to match your team's needs and deal timeline. Rather than a fixed trial period, we work with each firm to ensure you have enough time to properly evaluate Sage AI on actual deal work. This could mean testing it on a current transaction, analyzing a past deal, or setting up templates for upcoming projects. Schedule a quick call with us to discuss your specific requirements and we'll design a trial that makes sense for your team."
						},
						{
							title: "I only do 1-5 deals per year, I wont use it that much.",
							id: "small",
							content:
								"Even with a smaller deal volume, Sage AI delivers significant value by dramatically reducing the time spent on each transaction. Whether you're working on one deal or twenty, tasks like analyzing data rooms, creating CIMs, and responding to buyer questions still consume dozens of hours per deal.\n\nOur platform helps you complete these tasks in a fraction of the time, letting you focus on high-value activities like client relationships and deal strategy. Plus, Sage AI's capabilities extend beyond just deal execution - you can use it for prospect research, pitch materials, and market analysis between transactions.\n\nMany of our users with similar deal volumes report that Sage pays for itself just by eliminating late nights and weekend work on their first deal."
						},
						{
							title: "My slides don't look like the ones Sage produces...",
							id: "slides",
							content:
								"While it's true that you'll need to format slides according to your firm's exact templates and branding, Sage AI saves you time where it matters most: gathering and organizing information.\n\nTypically, over half the time spent creating deal materials goes into finding relevant data, analyzing financials, verifying facts, and structuring content - not formatting slides. Sage AI eliminates these time-consuming tasks by instantly pulling all pertinent information from your data room, organizing it logically, and presenting it in a clear format that's easily transferable to your slides.\n\nThe platform also provides:\n\n - Accurate financial data and calculations\n\n - Ready-to-use charts and graphs\n\n - Pre-written content that can be quickly customized\n\n - Direct citations for every data point\n\n - Exportable tables and figures\n\nThis means you start with comprehensive, well-structured content instead of a blank page - then simply apply your firm's formatting standards to create polished, client-ready materials."
						}
					]}
				/>
			</PageLayout>
			<PageLayout>
				<H2>Want to learn more?</H2>
			</PageLayout>
			<Spacer>
				<CardList
					cards={[
						{
							title: "Schedule a Demo",
							image: "https://cdn.sageai.dev/website/assets/docs-athena-close-up-1.avif",
							content: "Developed from M&A processes at industry leading firms to address real-world challenges.",
							href: "#get-started",
							cta: "Schedule"
						},
						{
							title: "Examples",
							image: "https://cdn.sageai.dev/example-videos/1-business-description/thumbnail.png",
							content: "Check out some example videos of common tasks within Sage",
							href: "/examples",
							cta: "Watch"
						},
						{
							title: "Sage AI Data Security",
							content:
								"At Sage AI, data security is ensured through our three layer approach. Document Level Security, Account Level Security and System Level Security.\n\nEach tier of security & controls reinforces the others. To see exactly how this works, take a look at this page.",
							href: "/security/how-we-secure-your-data",
							image: "https://cdn.sageai.dev/website/assets/sage-ai-cloud-security-banner.avif"
						}
					]}
				/>
			</Spacer>
		</section>
	);
}
