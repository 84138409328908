import { Col } from "../../Col";
import { FaIcon } from "../../FaIcon";
import { Row } from "../../Row";
import "./ReviewCard.scss";

export function ReviewCard({ children, name, image, kicker }) {
	return (
		<div className="__sage-review-card">
			<Col gap="1rem">
				<div
					className="img"
					style={{ backgroundImage: `url("${image}")` }}
				/>
				<div className="kicker">{kicker}</div>
				<div className="content">{children}</div>
				<Row
					wrap={false}
					verticalAlign="center"
				>
					<FaIcon
						icon="badge-check"
						color="#0094ff"
						size="xl"
						padding="0"
						minWidth="0"
					/>
					<div className="name">{name}</div>
				</Row>
			</Col>
		</div>
	);
}
