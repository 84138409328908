import { Col } from "../Col";
import { FaIcon } from "../FaIcon";
import { Row } from "../Row";
import "./InfoLayout.scss";

export function InfoLayout({ children, title, subtitle }) {
	return (
		<div className="__sage-info-layout">
			<div className="inner">
				<Row horizontalAlign="between">
					<div className="layout-title">{title}</div>
					<div className="layout-subtitle">{subtitle}</div>
				</Row>
				<div className="info-items">
					<Row
						wrap
						horizontalAlign="space-evenly"
						gap="1rem"
					>
						{children}
					</Row>
				</div>
			</div>
		</div>
	);
}

export function InfoItem({ children, title, icon }) {
	return (
		<div className="__sage-info-layout-item">
			<Col gap="1.5rem">
				<Row>
					<FaIcon
						icon={icon}
						color={"#FFFFFF"}
						hideBg
						padding="0"
						minWidth="0"
						size="5x"
					/>
				</Row>
				<div className="title">{title}</div>
				{children}
			</Col>
		</div>
	);
}

export function InfoItemBullet({ children }) {
	return <div className="__sage-info-layout-bullet">{children}</div>;
}
